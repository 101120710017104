import React, { useState } from 'react'
import "./vue.css";     
import dogLogo from "../../img/dog.jpeg"
import catLogo from "../../img/cat.jpeg"
import { AnimalIconContent, ClientIconContent, ConsultationIconContent, FactureIconContent, PrescriptionIconContent, VaccinIconContent } from '../common/IconFactory';
import ClientHeader from '../layout/ClientHeader';
import OrganizationChart from "organization-chart-react";
import { useSelector } from 'react-redux';
import TabSelector from '../layout/TabSelector';
import Animals from '../../api/Animals';
import { useDispatch } from "react-redux"
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import { messageStore } from '../common/InfoMessage';
import { logger } from '../common/Logger';


const animalsLogo = {"dog": dogLogo, "cat": catLogo}


function View360() {
  const dispatch = useDispatch()
  const pdfContentRef = React.createRef();

  const curClient = useSelector(state => state.client.client)
  //const curAnimal = useSelector(state => state.client.animal)
  
  const [clientNoLoad, setClientNoLoad] = useState(null);
  const [dataMap, setDataMap] = useState({});


  const loadAnimals = async (optAnimals) => {
    logger.debug("Loading animals (loadAnimals) : ", optAnimals)
    var animalData = []
    for (const animalKey of optAnimals) {
      dispatch(addLoadingCount())
      await new Animals().Get(animalKey)
        .then((animal) => {
        // logger.debug("Animal loaded : ", animal)
        animalData.push(animal)
        })
        .finally(()=>dispatch(subLoadingCount()))
    }

    return animalData
  }

  if (curClient?.payload && curClient?.payload.no !== clientNoLoad) {
    logger.debug("New client to load. #", curClient.payload.no)
    //setDataMap(constructClientRelation(curClient.payload))
    loadAnimalsList(curClient.payload.no, dispatch)
    .then(loadAnimals)
    .then((animalsData) => {
      logger.debug("Animals loaded")
      logger.debug("animals : ", animalsData)
      const clientCard = constructClientCard(curClient.payload)
      const animalsCard = constructAnimalsCard(animalsData)
      const billSection = constructBillSection(curClient.payload)
      const clientChildren = [billSection, ...animalsCard].filter(n => n)
      clientCard.children = clientChildren.length === 0 ? null : clientChildren

      setDataMap(clientCard)
    })
    setClientNoLoad(curClient.payload.no)
  }

  return ( 
    <>
      <ClientHeader showAnimals={false}/>
      <TabSelector 
        pageTitle="Vue 360"
        availableView={[{key: 0, title: "Dossier client", name: "view"}]}
        currentView={"view"}
        />
        
        <div ref={pdfContentRef} className="card overflow-x-auto">
               
          <OrganizationChart data={dataMap} onClickNode={(data) => {}}  />
        </div>
        
    </>)
}

const loadAnimalsList = (clientNo, dispatch) => {
  dispatch(addLoadingCount())
  return new Animals().List()
      .then((allAnimals) => {
        const optAnimals = allAnimals.filter(a => a.substring(0, a.indexOf("/")) === clientNo.toString())
        //logger.debug("optionsAnimals : ", optAnimals)
        return optAnimals    
      })
      .catch((error) => {messageStore.sendMessage(error.message, "error")})
      .finally(()=> dispatch(subLoadingCount()))
}





const getBillCard = (title, items) => {
  var childrens = []
  if (items && items.length > 0) {
    for (const childs of items) {
      childrens.push({
        name: "#" + childs.no + "  " + childs.date,
        add: childs.total + "$",
      })
    }
  

    return {
      title: title,
      member: childrens
    }
  }
  return null
}


const getComponentCard = (title, items, icon) => {
  var childrens = []
  if (items && items.length > 0) {
    for (const childs of items) {
      childrens.push({
        name: "#" + childs.no + "  " + childs.date,
      })
    }
  

    return {
      title: icon ? <>{icon} {title}</> : title,
      member: childrens
    }
  }
  return null
}

const constructClientCard = (client) => {
  return {
      title: <><ClientIconContent/>{client.name}</>,
      member: [{
        name: client.email,
        add: client.phone,
        image_url: 
          "https://github.com/LeeJams/LeeJams.github.io/blob/master/assets/img/user.jpg?raw=true"
      }]
    }
}

const constructBillSection = (client) => {

  const billNotPaid = getBillCard("Non payé", client.bills?.filter(b => !b.paid))
  const billPaid = getBillCard("Pay.", client.bills?.filter(b => b.paid))
  const mergedBills = [billPaid, billNotPaid].filter(n => n)
  logger.debug("mergedBills : ", mergedBills)
  var billSection = null
  if (mergedBills.length > 0) {
    billSection = {
        title: <><FactureIconContent />Facture</>,
        children: mergedBills
      }
  }
  return billSection
}


const constructAnimalsCard = (animals) => {
  var data = []
  for (const animal of animals) {
    data.push(constructAnimalCard(animal))
  }
  return data
}

const constructAnimalCard = (animal) => {
  const vaccins = getComponentCard("Vaccin", animal.vaccines, <VaccinIconContent/>)
  const rxs = getComponentCard("Rx", animal.rxs, <PrescriptionIconContent/>)
  const consultations = getComponentCard("Consultation", animal.consultations, <ConsultationIconContent />)

  const children = [vaccins, rxs, consultations].filter(n => n)
  return {
    title: <><AnimalIconContent /> {animal.name}</>,
    member: [{
      name: animal.name,
      add: animal.race + animal.sex,
      image_url: animalsLogo[animal.type]
    }],
      children: children.length === 0 ? null : children
    }
}

  export default View360