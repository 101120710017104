import React from 'react';
import { styled, TextField } from "@mui/material";

//const TextFieldStyle = styled(TextField)({ marginTop:16, marginBottom:16 });

const TextFieldStyle = styled((props) => (
    <TextField
      {...props}
      slotProps={{
        ...props.slotProps,
        input: {
          ...props.slotProps?.input
        },
        inputLabel: { ...props.slotProps?.inputLabel,
          shrink: true 
        },
        htmlInput: { 
          ...props.slotProps?.htmlInput,
          "data-testid": props.testid,
          maxLength: props.maxLength || props.slotProps?.htmlInput?.maxLength, minLength: props.minLength || props.slotProps?.htmlInput?.minLength
        }
      }}
    />
  ))({
    marginTop: 16,
    marginBottom: 16,
  });


export const StandardTextField = styled((props) => (
  <TextField
    {...props}
    slotProps={{
      ...props.slotProps,
      input: {
        ...props.slotProps?.input
      },
      htmlInput: { 
        ...props.slotProps?.htmlInput,
        "data-testid": props.testid,
        maxLength: props.maxLength || props.slotProps?.htmlInput?.maxLength, minLength: props.minLength || props.slotProps?.htmlInput?.minLength
      }
    }}
  />
))({
  marginTop: 16,
  marginBottom: 16,
});


export default TextFieldStyle