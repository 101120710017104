import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FlatHeaderRenderer from '../../header/FlatHeaderRenderer';
import { formatToDisplayFormatFromString } from '../../date/DateUtils'
import { useSelector } from 'react-redux';
import {ViewButtonAnimalPartSection} from '../../view/ViewButtonSection';
import { handleDownload } from '../../GeneratePDF';
import EmptyView from '../../view/EmptyView';
import ConsultationSelector from './ConsultationSelector';
import { GetConsultationSection } from './BaseConsultation';
import { useTranslation } from 'react-i18next';
import { LoadHeader } from '../../headers/HeaderLoader';

export function ViewConsultation({itemNo, handleEdit}) {
  const pdfContentRef = React.createRef();
  const [isPrintMode, setIsPrintMode] = useState(false);

  const header = useSelector(state => state.common.header)
  const curClient = useSelector(state => state.client.client)
  const curAnimal = useSelector(state => state.client.animal)
  const [consultation, setConsultation] = useState(itemNo ? curAnimal?.payload?.consultations?.find(obj => obj.no === itemNo) : null);




  if (consultation && (curAnimal?.payload?.consultations === undefined ||  curAnimal?.payload?.consultations.findIndex(obj => obj.no === consultation.no) === -1)) {
    setConsultation(null)
    return (<GetEmptyView animal={curAnimal} setItem={setConsultation} />)
  }


  const getPdfName = () => {
    return consultation.no + "-" + curClient.payload.name + "-" + curAnimal.payload.name + ".pdf"
  }
/*
<InfoItemTooltip
                      noName="No"
                      no={consultation.no} 
                      updateDate={consultation.updateDate} 
                      creationDate={consultation.creationDate} />
*/
  const getView =(header, client, animal, consultation, isPrintMode) => {
    return (
        <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
          <LoadHeader />
            <Typography color="text.secondary" align='center'>
              <ConsultationSelector animal={animal} consultation={consultation} setConsultation={setConsultation}/>
            </Typography>
            <div ref={pdfContentRef} border="1" style={{maxWidth: isPrintMode ? '780px' : ''}}>
                <FlatHeaderRenderer 
                    isPrintMode={isPrintMode}
                    headerData={header} 
                    clientData={client} 
                    animalData={animal}
                    extraData={{"date": formatToDisplayFormatFromString(consultation.date)}}  
                />
                <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" variant="h5">
                  Détails de la consultation
                </Typography>

                <GetConsultationSection 
                    consultationData={consultation.consultation} 
                    animalData={animal}
                    isEditMode={false} handleInputChange={(e)=> {}}
                    isPrintMode={isPrintMode}/>


            </div>
            <ViewButtonAnimalPartSection 
                isEditMode={true}
                animal={animal}
                allowDelete={true}
                itemArrayName="consultations"
                selectedItem={consultation}
                handleEdit={() => handleEdit(consultation)}
                handleDownload={() => handleDownload(setIsPrintMode, pdfContentRef, getPdfName())}
            />
        </Paper>
      );
    }


    

  if (consultation === undefined || consultation === null) {
    return (<GetEmptyView animal={curAnimal} setItem={setConsultation} />)
  }

  return getView(header?.payload, curClient?.payload, curAnimal?.payload, consultation, isPrintMode)
    
}


const GetEmptyView = ({animal, setItem}) => {
  const { t, i18n } = useTranslation();
  return (
    <EmptyView title={t('consultation.noSelection')}>
      <ConsultationSelector animal={animal?.payload} setConsultation={setItem}/>
    </EmptyView>
  )
}
