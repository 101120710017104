import React  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import Clients from "../../api/Clients"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { setRefreshClient } from '../common/ClientSlice';
import { messageStore } from '../common/InfoMessage';
import { selectOptionTitreNoKey } from '../common/SelectorUtils';
import ReactGA from "react-ga4";
import { logger } from '../common/Logger';

const ClientSelector = ({clientKey, setClient, setClientKey, disable, loadClient}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const refreshClient = useSelector(state => state.client.refreshClient);

  const [optionsClients, setOptionsClients] = useState(null)

    useEffect(() => {
      if (optionsClients === null || refreshClient.payload === true) {
        dispatch(addLoadingCount())
        new Clients().List()
          .then((allClients) => {
            setOptionsClients(allClients)
            dispatch(setRefreshClient(false))
          })
          .catch((error) => {messageStore.sendMessage(error.message, "error")})
          .finally(()=>dispatch(subLoadingCount()))
      }
    
    }, [refreshClient]);
      
  logger.trace("In client selector : clientKey = ", clientKey)
    
  const InternalOnChange = (event, newKey) => {
    ReactGA.event("select_item", {"item_list_name": "animal", "items": [{"item_id" : newKey, "item_name" : selectOptionTitreNoKey(newKey)}] });
    
    if (setClientKey) {
      setClientKey(newKey)
    }

    if (loadClient) {
      dispatch(addLoadingCount())
      new Clients().Get(newKey)
        .then(setClient)
        .finally(()=>dispatch(subLoadingCount()))
    }
  }


  return (<Autocomplete
      aria-label= "lstClients"
      disabled={disable}
      disableClearable
      onChange={InternalOnChange}
      sx={{ maxWidth: 500, width: '100%' }}
      value={clientKey === undefined ? null : clientKey}
      options={optionsClients ? optionsClients : []}
      getOptionLabel={selectOptionTitreNoKey}
      renderInput={(params) => <TextField inputProps={{'aria-label': "lstClients"}} {...params} label={t('client.selectorTitle')}/>}
    />)
  }
  

  ClientSelector.propTypes = {
    clientKey: PropTypes.string,
    loadClient: PropTypes.bool,
    setClient: PropTypes.func.isRequired,
    setClientKey: PropTypes.func,
    disable: PropTypes.bool
  };

  export default ClientSelector