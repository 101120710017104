import { Autocomplete, TextField } from "@mui/material"
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const BillSelector = ({client, bill, setBill, disable}) => {


  const intenalOnChange = (event, newValue) => {
    ReactGA.event("select_item", {"item_list_name": "bill", "items": [{"item_id" : newValue.no, "item_name" : selectOptionTitre(newValue), "item_category": client.no }] });
    setBill(newValue)
  }

  const { t, i18n } = useTranslation();
  return (
    <Autocomplete
      aria-label="lstBills"
      disabled={disable}
      disablePortal
      disableClearable
      onChange={intenalOnChange}
      sx={{ maxWidth: 500, width: '100%%', my: 5, mx: 2 }}
      value={bill}
      options={client?.bills ? client.bills : []}
      getOptionLabel={selectOptionTitre}
      renderInput={(params) => <TextField {...params} label={t('billing.selectorTitle')}/>}
    />)
  }
  
  const selectOptionTitre = (option) => {
    var paidLabel = option.isPaid ? "" : " - Non payé" 
    paidLabel = "#" + option.no + "  "+ option.date + paidLabel
    paidLabel = option.animal?.name ? paidLabel + " (" + option.animal.name + ")" : paidLabel

    return paidLabel
  }

  BillSelector.propTypes = {
    client: PropTypes.object,
    bill: PropTypes.object,
    setBill: PropTypes.func.isRequired,
    disable: PropTypes.bool
  };
  

  export default BillSelector