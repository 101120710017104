import React, { useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { Form, useNavigate } from 'react-router-dom';
import { Authenticate } from '../../services/authenticate';
import logo from '../../img/logo-nb.png'
import {messageStore} from '../common/InfoMessage'
import { useDispatch } from 'react-redux';
import { setUser } from '../common/SessionSlice';
import { useTranslation } from 'react-i18next';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import { hasMinLength } from './PasswordUtil';
import { changePwsUrl, signupUrl } from '../Navigator';
import ReactGA from "react-ga4";
import { logger } from '../common/Logger';
import TextFieldStyle from '../input/TextFieldStyle';

const Login = () => {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
    if (formField === "password") {
      setPassword(value);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {

      if (email === '' && password === '') {
        
        messageStore.sendMessage(t('session.isRequired'), "warning", t('session.username'))
        messageStore.sendMessage(t('session.isRequired'), "warning", t('session.password'))
        resolve({ email: "Email is Required", password: "Password is required" });
      } else if (email === '') {
        messageStore.sendMessage(t('session.isRequired'), "warning", t('session.username'))
        resolve({ email: "Email is Required", password: "" });
      } else if (password === '') {
        messageStore.sendMessage(t('session.isRequired'), "warning", t('session.password'))
        resolve({ email: "", password: "Password is required" });
      } else if (!hasMinLength(password)) {
        messageStore.sendMessage(t('session.changePws.newPwsRestriction'), "warning", t('session.password'))
        resolve({ email: "", password: "must be 8 character" });
      } else {
        resolve({ email: "", password: "" });
      }
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    validation()
      .then((res) => {
        if (res.email === '' && res.password === '') {
          dispatch(addLoadingCount())
          Authenticate(email, password)
          .then((data)=>{
            if (data?.isFirstLoging) {
              messageStore.sendMessage(t('session.passwordChangeNeed'), "info")
              navigate(changePwsUrl,  { state: {"email": email}});
            } else {
              ReactGA.event("login");
              dispatch(setUser(email))
              messageStore.sendMessage(t('session.loginSucces'), "info", t('session.login'))
              navigate('/');
            }
          },(err)=>{
            //dispatch(setUser(null))
            messageStore.sendMessage(err.message, "error")
            logger.error(err);
          })
          .catch(err=>{
            logger.error(err)
            messageStore.sendMessage(err, "error")
            //dispatch(setUser(email))
          })
          .finally(()=>dispatch(subLoadingCount()))
        }
      }, err => logger.debug(err))
      .catch(err => logger.debug(err));
  }

  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <img src={logo} className="App-MainLogo" alt="logo" width={1000} height={60}/>
        <Box
            component="nav"
            sx={{ flexShrink: { sm: 0 } }} >
              {t('session.title')}
        </Box>
        <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
        <div className="login">
          <div className='form'>
            <form onSubmit={handleClick}>
            <div className="formfield">
              <TextFieldStyle autoComplete='on'
                testid="txtEmail"
                value={email}
                onChange={(e) => formInputChange("email", e.target.value)}
                label={t('session.username')}
              />
            </div>
            <div className='formfield'>
              <TextFieldStyle autoComplete='on' 
                testid="txtPassword"
                maxLength={60}
                value={password}
                onChange={(e) => { formInputChange("password", e.target.value) }}
                type="password"
                label={t('session.password')}
              />
            </div>
              
            <div className='formfield'  >
              <Button data-testid="btnLogin" type='submit' variant='contained' sx={{ my: 1 }}>{t('session.login')}</Button>
              <br/>
              <Button data-testid="btnSignup" variant='contained' onClick={() => navigate(signupUrl)} >{t('signup.create')}</Button>
            </div>
            </form>
          </div>
          </div>
      </Box>
    </Box>
  )
}

export default Login