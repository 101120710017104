
import {ListElements, GetElement, UpdateElement, GetDataElement, internalPutElement} from './S3Helper';

const ELEMENT_NAME = "headers"

class Headers {
    List() {
       return new ListElements(ELEMENT_NAME)
    }
 
    Get(headerName) {
       return new GetElement(ELEMENT_NAME, headerName)
    }
    GetData(headerName) {
       return new GetDataElement(ELEMENT_NAME, headerName)
    }
    Update(headerData) {
      return new UpdateElement(ELEMENT_NAME, "default.json", headerData)
   }
   SaveImage(fileName, data) {
      return internalPutElement(ELEMENT_NAME, fileName, data)
   }
 }

export default Headers

