import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ConfigWeightSection = ({item, handleConfigInputChange, isEditMode}) => {
    const { t, i18n } = useTranslation(); 
    return (
        <Typography color="text.secondary" align="left" component={'div'}>
            <Box spacing={2} align="left" display={'block'} >
                <Box>
                    {t('header.config.weight')}
                </Box>
                <Box>
                <GetWeightRadio 
                    displayName={t('consultation.title')} 
                    itemName="consultation" 
                    item={item} 
                    handleConfigInputChange={handleConfigInputChange} 
                    isEditMode={isEditMode} 
                    t={t} /> 
               </Box>
               <Box>
                <GetWeightRadio 
                    displayName={t('rx.title')} 
                    itemName="prescription" 
                    item={item} 
                    handleConfigInputChange={handleConfigInputChange} 
                    isEditMode={isEditMode} 
                    t={t} /> 
               </Box>
               <Box>
                <GetWeightRadio 
                    displayName={t('vaccine.title')} 
                    itemName="vaccin" 
                    item={item} 
                    handleConfigInputChange={handleConfigInputChange} 
                    isEditMode={isEditMode} 
                    t={t} /> 
               </Box>
               <Box>
                <GetWeightRadio 
                    displayName={t('header.config.other')} 
                    itemName="other" 
                    item={item} 
                    handleConfigInputChange={handleConfigInputChange} 
                    isEditMode={isEditMode} 
                    t={t} /> 
               </Box>
            </Box>
        </Typography>
    )
}




const GetWeightRadio = ({displayName, itemName, item, handleConfigInputChange, isEditMode}) => {
    const { t, i18n } = useTranslation(); 
    return (
    <FormControl>
        <FormLabel >{displayName}</FormLabel>
        <RadioGroup 
            row
            onChange={(e) => handleConfigInputChange(itemName, e.target.value, "weight")}
            value={item.weight[itemName]}>
            <FormControlLabel disabled={!isEditMode} value={"kg"} control={<Radio />} label={t('header.config.kg')} />
            <FormControlLabel disabled={!isEditMode} value={"lb"} control={<Radio />} label={t('header.config.lb')} />
        </RadioGroup>
    </FormControl> )
}



export default ConfigWeightSection