import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import React, { useEffect }  from 'react';
import TagManager from 'react-gtm-module';
import userpool from './component/userpool';
import LoginUI from './component/session/LoginUI';
import Logout from './component/session/Logout';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import CieManager from './component/managment/cie/CieManager';
import CustomThemeComponent from './component/CustomThemeComponent'
import Navigator, { animalUrl, billUrl, clientUrl, consultationUrl, eventsConfirmUrl, eventsRequestUrl, accountUrl, overviewUrl, rxUrl, vaccineUrl, view360Url, loginUrl, logoutUrl, resetPwsUrl, changePwsUrl, profileUrl, signupUrl } from './component/Navigator';
import Top from './component/layout/Top';
import Copyright from './component/Copyright';
import {GetAccessToken} from './services/authenticate'
import InfoMessage from './component/common/InfoMessage'
import View360 from './component/vue360/View360';
import RxManager from './component/medic/rxs/RxManager';
import BillManager from './component/factures/BillManager';
import VaccineManager from './component/medic/vaccines/VaccineManager';
import ClientManager from './component/clients/ClientManager';
import AnimalManager from './component/animals/AnimalManager';
import ConsultationManager from './component/medic/consultations/ConsultationManager';
import OverviewManager from './component/overview/OverriewManager';
import EventsManager from './component/events/EventsManager';
import EventsConfirmManager from './component/eventsConfirm/EventsConfirmManager';
import ProfileManager from './component/managment/profile/ProfileManager';
import { LoadingScreen } from './component/common/LoadingSlice';
import { hasModuleBilling, hasModuleConsultation, hasModuleEventConfirm, hasModuleInccomingEvent, hasModuleRx, hasModuleVaccine } from './services/AccessHub';
import { SetupI18n } from './i18n';
import ChangePasswordUI from './component/session/ChangePasswordUI';
import env from './env/env.json';
import {logger} from './component/common/Logger';
import SignupUI from './component/session/SignupUI';

const drawerWidth = 256;

function App() {
  const username = userpool.getCurrentUser()?.getUsername()
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(()=>{
    const tagManagerArgs = {
      gtmId: env.GTM
    };
    TagManager.initialize(tagManagerArgs);


    if(username){
      logger.debug("Loggué");
      
    } else {
      logger.debug("pas loggué");
      navigate(loginUrl)
    }
  },[]);

  const defaultPage = hasModuleInccomingEvent() ? <EventsManager /> : <ClientManager />;

  return (
     
      <div className="App">
        <CustomThemeComponent>
          <Box sx={{ display: 'flex' ,minHeight: '100vh' }}>
            <CssBaseline />
            
            {userpool.getCurrentUser() && 
              <Box component="nav" key='boxNav'
                  sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                  >
                  <Navigator key='nav-temp' 
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                  /> 
                  <Navigator key='nav-permanent'
                    PaperProps={{ style: { width: drawerWidth } }}
                    sx={{ display: { sm: 'block', xs: 'none' } }}
                  />
                </Box>
              }
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                {userpool.getCurrentUser() && 
                  <>
                    <Top onDrawerToggle={handleDrawerToggle}
                        username={username}
                    />
                  </>
                }
                
                <InfoMessage />
                <LoadingScreen />

                { GetAccessToken() &&
                  <SetupI18n />
                }
                  <Routes >
                  
                    { GetAccessToken() &&
                    <>
                      <Route exact path={profileUrl} element={<ProfileManager />}/>
                      
                      <Route exact path={accountUrl} element={<CieManager />}/>
                      {hasModuleConsultation() &&
                        <Route exact path={consultationUrl} element={
                          <ConsultationManager />}
                        />
                      }
                      {hasModuleInccomingEvent() &&
                        <Route exact path={eventsRequestUrl} element={<EventsManager />}/>
                      }
                      {hasModuleEventConfirm() &&
                        <Route exact path={eventsConfirmUrl} element={<EventsConfirmManager />}/>
                      }
                      {hasModuleRx() &&
                        <Route exact path={rxUrl} element={
                          <RxManager />
                        }/>
                      }
                      {hasModuleBilling() &&
                        <Route path={billUrl} element={<BillManager/>} />
                      }
                      {hasModuleVaccine() &&
                        <Route path={vaccineUrl} element={
                            <VaccineManager />
                          }/>
                      }
                      <Route path={view360Url} element={<View360 />}/>
                      <Route path={overviewUrl} element={
                          <OverviewManager />
                      }/>
                      <Route exact path={clientUrl} element={<ClientManager />}/>
                      <Route exact path={animalUrl} element={<AnimalManager/> } />
                      <Route path={logoutUrl} element={<Logout />}/>
                      <Route path="*" element={defaultPage}/> 
                    </>
                    }
                    { !GetAccessToken() &&
                      <>
                        <Route path='*' element={<LoginUI />}/>
                        <Route path={loginUrl} element={<LoginUI />}/>
                        <Route path={signupUrl} element={<SignupUI />}/>
                      </>
                    }

                    <Route path={resetPwsUrl} element={<>TODO</>}/>
                    <Route path={changePwsUrl} element={<ChangePasswordUI/>}/>
                  </Routes>
              
                <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
                  <Copyright />
                </Box>
              </Box>



            

          </Box>
        </CustomThemeComponent>

        

      </div>
  );
}

export default App;
