import { createSlice } from '@reduxjs/toolkit'
import { logger } from './Logger'

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    isAdmin: false,
    accessToken: "",
    isLog: false,
    user: null,
    userSession: {},
    awsCredentials: {}
  },
  reducers: {
    setUser: (state, curUser) => {
      logger.trace("store : setUser", curUser)
      state.user = curUser
    },
    setIsAdmin: (state, isAdmin) => {
      logger.trace("store : setIsAdmin", isAdmin)
      state.isAdmin = isAdmin
    },
    setUserSession: (state, session) => {
      logger.trace("store : setSession")
      state.userSession = session
    },
    setAwsCredentials: (state, awsCredentials) => {
      logger.trace("store : setAwsCredentials")
      state.awsCredentials = awsCredentials
    }
  }
})


export const getIdToken = (userSession) => {
  return userSession?.payload?.idToken
}
export const getJwtToken = (userSession) => {
  return userSession?.payload?.idToken?.jwtToken
}

// Action creators are generated for each case reducer function
export const { setUser, setIsAdmin, setUserSession, setAwsCredentials} = sessionSlice.actions

export default sessionSlice.reducer
