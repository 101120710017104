import React from 'react'
import { formatToSystemDate } from "../../date/DateUtils";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

const emptyPrescription = {isActif: true, 
    "date": formatToSystemDate(new Date()), 
    "items":[], 
    "no": "",
    "type": "1",
    "consultationNo": ""}

const emptyItem = {"name": "", "posologie": ""}

const typeMap = { "1": "Prescription à envoyer",
                "2": "Préparation de prescription",
                "3": "Prescription vierge"}

const GetTypeSelector = ({curValue, onChangeHandler, isEditMode}) => {
    return (
    <FormLabel id="type" sx={{  my: 2, mx: 2 }}>Type de prescription
        <RadioGroup sx={{ mx: 2 }}
            disabled={!isEditMode}
            row
            aria-labelledby="type"
            name="radio-buttons-group"
            value={curValue}
            onChange={(e) => onChangeHandler(null, 'type', e.target.value)}
            >
            {Object.keys(typeMap).map((key, i) => (
                <FormControlLabel key={i} required value={key} control={<Radio inputProps={{ 'aria-label': "opt"+key }}/>} label={typeMap[key]} />
            ))}
        </RadioGroup>
    </FormLabel>)
}

export { emptyPrescription, emptyItem, typeMap, GetTypeSelector}