import React, { useEffect, useState } from 'react'
import EmptyView from '../../view/EmptyView';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ConfigCieHeader from './section/ConfigCieHeader';
import ConfigLogo from './section/ConfigLogo';
import { useDispatch, useSelector } from 'react-redux';
import {CreateButtonSection} from '../../view/CreateButtonSection';
import {updateHeader} from './BaseHeader'
import { LoadHeader } from '../../headers/HeaderLoader';
import ConfigTax from './section/ConfigTax';

export function CieHeaderView() {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()

  const sliceHeader = useSelector(state => state.common.header)?.payload
  const [curHeader, setCurHeader] = useState();

  useEffect(() => {
    if (curHeader === undefined || curHeader === null) {
      setCurHeader(sliceHeader)
    }
  
  }, [sliceHeader]);

  if (curHeader === undefined || curHeader === null) {
    return (
      <EmptyView title={t('header.noSelection')}>
          <LoadHeader />
      </EmptyView>
    )
  }


  const handleInputChange = (fieldName, value) => {
    setCurHeader( {...curHeader,
      [fieldName]: value
    })
  };

  const getView =(curHeader) => {
    return (
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
        <LoadHeader />
        <div border="1" >
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" variant='h5'>
            {t('header.description')}
            </Typography>
            <form id="cieForm" onSubmit={(e) => updateHeader(e, curHeader, null, dispatch, t)}>
              <Box sx={{ my: 2, mx: 4 }} >
                <ConfigLogo />
                <ConfigCieHeader item={curHeader} handleInputChange={handleInputChange} isEditMode={true} />
                <ConfigTax item={curHeader} handleInputChange={handleInputChange} isEditMode={true} />
                <CreateButtonSection />
              </Box>
            </form>
          </div>
        </Paper>
      )
  }

    return getView(curHeader)
}

