import { createSlice } from '@reduxjs/toolkit'
import { logger } from './Logger'

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    header: null,
    limits: {},
    reloadLimits: true,
    logo: null,
    signature: null
  },
  reducers: {
    setHeader: (state, headerData) => {
      logger.trace("store : setHeader", headerData)
      state.header = headerData
    },
    setLimits: (state, limitsData) => {
      logger.trace("store : setLimits", limitsData)
      state.limits = limitsData
      state.reloadLimits = false
    },
    askReleaseLimits: (state) => {
      logger.trace("store : askRelease")
      state.reloadLimits = true
    },
    setLogo: (state, logo) => {
      logger.trace("store : setLogo")
      state.logo = logo;
    },
    setSignature: (state, sign) => {
      logger.trace("store : setSignature")
      state.signature = sign;
    }
  }
})

export const GetWeightConf = (conf) => {
  return conf?.weight
}
export const GetWeightType = (conf, type) => {
  return GetWeightConf(conf) ? GetWeightConf(conf)[type] : null
}
export const GetPaiementEmail = (conf) => {
  return conf?.paiement_email
}
export const GetLicense = (conf) => {
  return conf?.license
}
// Action creators are generated for each case reducer function
export const { setHeader, setLogo, setSignature, setLimits, askReleaseLimits } = commonSlice.actions

export default commonSlice.reducer