import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import {ViewButtonSection} from '../view/ViewButtonSection';
import { handleDownload } from '../GeneratePDF';
import EmptyView from '../view/EmptyView';
import { GetClientSection } from './BaseClient';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Clients from '../../api/Clients';
import { setClient, setRefreshClient } from '../common/ClientSlice';
import { messageStore } from '../common/InfoMessage';
import { hasDeletePermission } from '../../services/AccessHub';

export function ViewClient({handleEdit}) {
  const [t, i18n] = useTranslation()
  const navigate = useNavigate();
  const pdfContentRef = React.createRef();
  const [isPrintMode, setIsPrintMode] = useState(false);

  const curClient = useSelector(state => state.client.client)

  const getPdfName = () => {
    return curClient.no + "-" + curClient.payload.name + ".pdf"
  }
    
  const getView =(client, isPrintMode) => {

    return (
        <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
          <div ref={pdfContentRef} border="1" style={{maxWidth: isPrintMode ? '780px' : ''}}>
          <GetClientSection
            clientData={client} isEditMode={false} isPrintMode={isPrintMode} handleInputChange={(e)=> {}}/>

          </div>

        <ViewButtonSection 
            isEditMode={true}
            selectedItem={client}
            handleEdit={() => handleEdit(client)}
            allowDelete={hasDeletePermission()}
            handleDelete={deleteClient}
            handleDownload={() => handleDownload(setIsPrintMode, pdfContentRef, getPdfName())}
        />
        </Paper>
    );
    }


  

    if (curClient === undefined || curClient === null || curClient.payload === null) {
      return (
        <EmptyView title={t('client.noSelection')} />
      )
    }

    return getView(curClient?.payload, isPrintMode)
    
}


const deleteClient = (selectedItem, dispatch, t) => {
  return new Clients().delete(selectedItem.key).then((result) => {
    if (result.status === 200) {
      dispatch(setClient(null))
      dispatch(setRefreshClient(true))
      messageStore.sendMessage(t('message.deleteSuccess'))
    } else {
      messageStore.sendMessage(result.statusText, "error", t('message.deleteError'))
    }
})
//.then(() => window.location.reload())
.catch((error) => messageStore.sendMessage(error.message, "error", t('message.deleteError')))
}
