import { messageStore } from "../common/InfoMessage";

export const hasMinLength = (password) => {
    if (password.length < 8) {
        return false;
    }
    return true
}


export const validateNewPassword = (password, newPassword, confirmNewPassword, t) => {
    if (newPassword === '') {
        messageStore.sendMessage(t('session.isRequired'), "warning", t('session.changePws.newPws'))
        return false
    } else if (!hasMinLength(newPassword)) {
        messageStore.sendMessage(t('session.changePws.newPwsRestriction'), "warning", t('session.changePws.newPws'))
        return false
    } else if (newPassword !== confirmNewPassword) {
        messageStore.sendMessage(t('session.changePws.bothPwsEqual'), "warning", t('session.changePws.newPws'))
        return false
    } else if (newPassword === password) {
        messageStore.sendMessage(t('session.changePws.newPwsDifferent'), "warning", t('session.changePws.newPws'))
        return false
    }
    return true

}