import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';


export const loadingSlice = createSlice({
  name: 'loading',
  initialState : {
    value: 0
  },
  reducers: {
    addLoadingCount: state => {

      state.value += 1
    },    
    subLoadingCount: state => {
      if (state.value > 0) {
        state.value -= 1
      }
    },
    resetLoading: state => {
      state.value = 0
    }
  }
})


function LoadingScreen() {
  const loadingCount = useSelector(state => state.loading.value)

  const loadingShow = loadingCount > 0
  
  return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingShow}
        >
        <CircularProgress aria-label='Loading' color="inherit" />
      </Backdrop>
  ) 
}

export {LoadingScreen}



export const { addLoadingCount, resetLoading, subLoadingCount } = loadingSlice.actions

export default loadingSlice.reducer