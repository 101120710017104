import { React} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addLoadingCount, subLoadingCount } from "../common/LoadingSlice";
import { useEffect } from "react";
import { setLimits } from "../common/CommonSlice";
import { messageStore } from '../common/InfoMessage';
import Limits from '../../api/Limits';


//Use to load and story in cache the remaning action for the compagnie from the server
//Include number of RXs, Vaccines, ...

export const LoadActionsLimit = () => {
    const dispatch = useDispatch()
    const mustReload = useSelector(state => state.common.reloadLimits)
    const curLimits = useSelector(state => state.common.limits)

    useEffect(()=>{
        if (mustReload) {
          dispatch(addLoadingCount())
          new Limits().Get()
            .then(setLimits)
            .then(dispatch)
            .catch((error) => {
                console.error("Error loading limits : ", error)
                messageStore.sendMessage("Error loading limits", "error")
                dispatch(setLimits({payload: "error"}))
            })
            .then(subLoadingCount)
            .then(dispatch)              
        }
      },[curLimits, mustReload]);
    return (<></>)
}
