import React, { useEffect }  from 'react';
import { ViewClient } from "./ViewClient"
import { CreateClient } from "./CreateClient"
import { useState } from "react"
import ClientHeader from "../layout/ClientHeader"
import TabSelector from "../layout/TabSelector"
import Clients from "../../api/Clients"
import { setClient } from "../common/ClientSlice"
import { useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next';
import { GetAllHeader, GetNoSelectionHeader } from '../view/HeadersUtils';
import ReactGA from "react-ga4";
import { GetClientKey } from '../../services/authenticate';
import { hasProfileClient } from '../../services/AccessHub';

const ClientManager = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const canCreate = !hasProfileClient()
  const [itemToEdit, setItemToEdit] = useState(null)
  const [currentView, setCurrentView] = useState("view")
  const [availViews, setAvailViews] = useState(GetNoSelectionHeader(t, canCreate))

  useEffect(() => {
    ReactGA.event("page_view", {"page_location": "clients", "client_id": GetClientKey(), "page_title": "clients_" + currentView});
  }, [currentView]);


  const handleEdit = (item) => {
    setItemToEdit(item)
    setAvailViews(GetAllHeader(t, canCreate))
    setCurrentView("edit")
  }
  const saveCallback = (item) => {
    new Clients().Get(item.key).then(setClient).then(dispatch)
    setItemToEdit(null)
    setAvailViews(GetNoSelectionHeader(t, canCreate))
    setCurrentView("view")
  }
  return (
    <>
      <ClientHeader allowSelection={currentView !== "edit"} showAnimals={false} showClients={currentView !== "create"}/>
      <TabSelector 
        onViewChange={setCurrentView}
        pageTitle={t('client.description')}
        availableView={availViews}
        currentView={currentView}
        />

        { currentView === "view" && <ViewClient handleEdit={handleEdit}/> }
        { currentView === "edit" && <CreateClient itemToEdit={itemToEdit} saveCallback={saveCallback}/> }
        { currentView === "create" && <CreateClient saveCallback={saveCallback}/> }
    </>
  );
}

export default ClientManager