import React from 'react';
import { Paper } from "@mui/material"
import { LogoEditSection } from "../../../common/Logo"
import { hasPermissionLogo } from "../../../../services/AccessHub"

const ConfigLogo = ({ }) => {
    return (
        <Paper elevation={2} sx={{my: 2}} component={'span'}>
            {hasPermissionLogo() &&
                <LogoEditSection />
            }
        </Paper>
    )
}


export default ConfigLogo 