import React from 'react';
import {hasModuleBilling, hasModuleConsultation, hasModuleEventConfirm, hasModuleInccomingEvent, hasModuleRx, hasModuleVaccine, hasPermissionUnlimitedAction, numberBillLimit, numberConsultationLimit, numberRxLimit, numberVaccineLimit} from '../../../../services/AccessHub'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { ConsultationIcon, EventConfirmIconContent, EventRequestIconContent, FactureIcon, PrescriptionIcon, VaccinIcon } from '../../../common/IconFactory';


export const ModuleRx = {title: "Rx", description: "Module permettant de gérer les prescriptions", price: "0", pricePeriode: "", logo: <PrescriptionIcon/>, shouldBeDisplay: true, haveIt: hasModuleRx()}
export const ModuleVaccine = {title: "Vaccin", description: "Module permettant de gérer les vaccins", price: "0", pricePeriode: "", logo: <VaccinIcon/>, shouldBeDisplay: true, haveIt: hasModuleVaccine()}
export const ModuleConsultation = {title: "Consultation", description: "Module permettant de gérer les consultations", price: "0", pricePeriode: "", logo: <ConsultationIcon/>, shouldBeDisplay: true, haveIt: hasModuleConsultation()}
export const ModuleBilling = {title: "Facturation", description: "Module permettant de gérer les factures", price: "0", pricePeriode: "", logo: <FactureIcon/>, shouldBeDisplay: true, haveIt: hasModuleBilling()}
export const ModuleEventRequest = {title: "Demande de consultation", description: "Module permettant de recevoir des demandes de consultations", price: "0", pricePeriode: "", logo: <EventRequestIconContent/>, shouldBeDisplay: hasModuleInccomingEvent(), haveIt: hasModuleInccomingEvent()}
export const ModuleConfirmEvent = {title: "Création de rendez-vous", description: "Module permettant de gérer les demandes de rendez-vous", price: "0", pricePeriode: "", logo: <EventConfirmIconContent/>, shouldBeDisplay: hasModuleEventConfirm(), haveIt: hasModuleEventConfirm()}


export const ActIllimited = {title: "Action illimité", description: "Donne accès à un nombre illimité d'actions", price: "79", pricePeriode: "/mois", logo: <AllInclusiveIcon/>, haveIt: hasPermissionUnlimitedAction()}
export const ActRx = {title: "Création Rx", description: "Nombre de prescriptions restantes", nb: numberRxLimit(), logo: <PrescriptionIcon/>, shouldBeDisplay: !hasPermissionUnlimitedAction(), haveIt: hasModuleRx()}
export const ActVaccine = {title: "Création vaccin", description: "Nombre de vaccins restantes", nb: numberVaccineLimit(), logo: <VaccinIcon/>, shouldBeDisplay: !hasPermissionUnlimitedAction(), haveIt: hasModuleVaccine()}
export const ActConsultation = {title: "Création consultation", description: "Nombre de consultations restantes", nb: numberConsultationLimit(), logo: <ConsultationIcon/>, shouldBeDisplay: !hasPermissionUnlimitedAction(), haveIt: hasModuleConsultation()}
export const ActBilling = {title: "Création facturation", description: "Nombre de factures restantes", nb: numberBillLimit(), logo: <FactureIcon/>, shouldBeDisplay: !hasPermissionUnlimitedAction(), haveIt: hasModuleBilling()}

export const Refill10Act = {title: "+10", description: "Redonne la possibilité de créer 10 factures,\n 10 prescriptions, 10 vaccins et 10 consultations", nb: "+10 actions", price: 10, shouldBeDisplay: !hasPermissionUnlimitedAction()}
export const Refill25Act = {title: "+25", description: "Redonne la possibilité de créer 25 factures, 25 prescriptions, 25 vaccins et 25 consultations", nb: "+25 actions", price: 20, shouldBeDisplay: !hasPermissionUnlimitedAction()}
export const Refill100Act = {title: "+100", description: "Redonne la possibilité de créer 100 factures, 100 prescriptions, 100 vaccins et 100 consultations", nb: "+100 actions", price: 65, shouldBeDisplay: !hasPermissionUnlimitedAction()}


export const MyPlanModuleList = [ModuleRx, ModuleVaccine, ModuleConsultation, ModuleBilling, ModuleEventRequest, ModuleConfirmEvent]
export const MyPlanActionList = (limits) => {
    return [ActIllimited, {...ActRx,
        nb: numberRxLimit(limits)},
        {...ActVaccine,
        nb: numberVaccineLimit(limits)},
        {...ActConsultation,
        nb: numberConsultationLimit(limits)},
        {...ActBilling,
        nb: numberBillLimit(limits)}
     ]
}

export const PossibleBuyActionList = hasPermissionUnlimitedAction() ? [] : [ActIllimited, Refill10Act, Refill25Act, Refill100Act]