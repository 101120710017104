import React  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import { useState } from "react"
import Events from "../../api/Events"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import { messageStore } from '../common/InfoMessage';

const EventSelector = ({event, setEvent, setEventKey, disable}) => {
  const { t, i18n } = useTranslation();
  const [optionsEvents, setOptionsEvents] = useState(null)
  const dispatch = useDispatch()

    if (optionsEvents === null) {
      dispatch(addLoadingCount())
      new Events().List()
        .then(setOptionsEvents)
        .catch((error) => {messageStore.sendMessage(error.message, "error")})
        .finally(()=>dispatch(subLoadingCount()))
    }

    const internalSetEvent = (ev, newValue) => {
      setEventKey(newValue)
      new Events().Get(newValue).then(setEvent)
    }
  
    return (<Autocomplete
        disabled={disable}
        disableClearable
        onChange={internalSetEvent}
        sx={{ maxWidth: 500, width: '100%%', my: 5, mx: 2 }}
        value={event ? event : null}
        options={optionsEvents ? optionsEvents : []}
        renderInput={(params) => <TextField {...params} label={t('event.selectorTitle')}/>}
      />)
  }
  
  EventSelector.propTypes = {
    event: PropTypes.object,
    setEvent: PropTypes.func.isRequired,
    setEventKey: PropTypes.func.isRequired,
    disable: PropTypes.bool
  };

  export default EventSelector