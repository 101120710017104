export const GetAllHeader = (t, canCreate) => {
  if (canCreate === false)
    return canCreate === false ? [getEdit(t), getView(t)] : [getCreate(t), getEdit(t), getView(t)]
}    

 export const GetNoSelectionHeader =  (t, canCreate) => {
    return canCreate === false ? [getView(t)] : [getCreate(t), getView(t)]
}

const getCreate = (t) => {
  return {key: 0, title: t('view.create'), name: "create" }
}

const getView = (t) => {
  return {key: 1, title: t('view.view'), name: "view"}
}


const getEdit = (t) => {
  return {key: 2, title: t('view.edit'), name: "edit"}
}
  