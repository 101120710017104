import { sexeMap, typeMap } from '../animals/BaseAnimal';
import React from 'react';
import { Grid, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

function AminalCard({animalData, isPrintMode}) {

  const { t, i18n } = useTranslation();
  return <>
        { animalData && JSON.stringify(animalData) !== JSON.stringify({}) &&  
          v2(animalData, isPrintMode, t)
      }
    </>
}
    
function v2(animalData, isPrintMode, t) {
  return (
  <Grid container spacing={0} columns={10} 
    className='header-grid-container' 
    sx={{ my: 0, mx: 0, color: 'gray', textAlign: 'left', typography: 'body2' }}>
    <Grid item xs={10} sx={{ color: 'rgb(90, 90, 90)' , typography: 'body1' }} className='header-grid-item'>
      <Typography sx={{ my: 1 }} variant="h6">
        Informations du patient
      </Typography>
    </Grid>

    <Grid item xs={4} className='header-grid-item' >{t('animal.item.name')} :</Grid>
    <Grid item xs={6} className='header-grid-item'>{animalData.name}</Grid>

    <Grid item xs={4} className='header-grid-item'>{t('animal.item.birthShort')} :</Grid>
    <Grid item xs={6} className='header-grid-item'>{animalData.birthdate ? animalData.birthdate : "Inconnue"}</Grid>

    <Grid item xs={4} className='header-grid-item'>{t('animal.item.species')} :</Grid>
    <Grid item xs={6} className='header-grid-item'>{typeMap[animalData.type]}</Grid>

    {animalData.race && <>
      <Grid item xs={4} >{t('animal.item.race')} :</Grid>
      <Grid item xs={6} >{animalData.race}</Grid>
    </>}
    {animalData.sexe && <>
      <Grid item xs={4} >{t('animal.item.sex')} :</Grid>
      <Grid item xs={6} >{sexeMap[animalData.sexe]}</Grid>
    </>}
    {animalData.color && <>
      <Grid item xs={4} >{t('animal.item.color')} :</Grid>
      <Grid item xs={6} >{animalData.color}</Grid>
    </>}
    {animalData.micropuce && <>
      <Grid item xs={4} >{t('animal.item.chipShort')} :</Grid>
      <Grid item xs={6} >{animalData.micropuce}</Grid>
    </>}
  </Grid>)
}

export default AminalCard