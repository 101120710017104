import env from '../env/env.json';
import axios from 'axios';
import {GetAccessToken, GetClientKey} from '../services/authenticate'
import Headers from './Headers';
import { logger } from '../component/common/Logger';


const ELEMENT_NAME = "action/decrease_limits"
const LIMITS_FILE_NAME = "limits.json"
class Limits {
    DecreateLimit(action) {
        const token = GetAccessToken() 
        const APIEndPoint = env.adminApi + ELEMENT_NAME
        const startAction = async () => {
            try {
              const response = await axios.post(APIEndPoint, { "action": action },
                {params: {
                    action
                  },
                    headers: {
                  "X-API-Key": env.adminKey,
                  "Content-Type": "application/json",
                  "Authorization": 'Bearer ' + token,
                  "ClientKey": GetClientKey()
                    }
                });
              //response.data = data
              logger.debug('Response:', response.data);
              return response
            } catch (error) {
              logger.error('Error DecreateLimit :', error);
              //throw new Error(error.message)
            }
        };
        return startAction(action) 
    }

    Get() {
      return new Headers().Get(LIMITS_FILE_NAME)
    }
 }

export default Limits

