import { logger } from '../component/common/Logger';
import env from '../env/env.json';
import {GetAccessToken} from '../services/authenticate'
import axios from 'axios';

const APIEndPoint =  env.adminApi + 'calendar'
const EventRequestConfirmationEndPoint =  env.adminApi + 'events/requestconfirmation'


function CalendarGet() {

    return fetch(APIEndPoint,{headers: {
            "X-API-Key": env.adminKey,
            "Content-Type": "application/json"
         }
         })
     .then((response) => response.json())
     .then((data) => {
        logger.debug(data);
        return (data.body);
     })
     .catch((err) => {
      logger.error(err.message);
      return [""]
     })
}


function CalendarUpdate(eventConfirmData) {

   const update = async (eventConfirmData) => {
      try {
         const response = await axios.post(EventRequestConfirmationEndPoint, eventConfirmData ,
            {headers: {
            "X-API-Key": env.adminKey,
            "Authorization": 'Bearer ' + GetAccessToken()
               }
            });
            logger.debug('Response:', response.data);
         return response
      } catch (error) {
         logger.error('Error InsertElement :', error);
         return error
      }
   }; 
   return update(eventConfirmData)
}

export {CalendarGet, CalendarUpdate}