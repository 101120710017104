import { React} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addLoadingCount, subLoadingCount } from "../common/LoadingSlice";
import { useEffect } from "react";
import { setHeader } from "../common/CommonSlice";
import Headers from '../../api/Headers';
import { messageStore } from '../common/InfoMessage';
import { logger } from '../common/Logger';

//Use to load and story in cache the compagnie headers from the server
//Include cie name, logo, and other information

export const LoadHeader = () => {
    const dispatch = useDispatch()
    const curHeader = useSelector(state => state.common.header)
    var isLoading = false
    useEffect(()=>{
        if (!curHeader && !isLoading) {
          dispatch(addLoadingCount())
          isLoading = true
          new Headers().Get("default.json")
            .then(setHeader)
            .then(dispatch)
            .catch((error) => {
                logger.error("Error loading header : ", error)
                messageStore.sendMessage(error, "error")
                dispatch(setHeader({payload: "error"}))
            })
            .then(()=>{
              dispatch(subLoadingCount())
              isLoading = false})
        }
      },[curHeader]);
    return (<></>)
}
