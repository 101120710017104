import Headers from "../../../api/Headers";
import { setHeader } from "../../common/CommonSlice";
import { messageStore } from "../../common/InfoMessage";
import { addLoadingCount, subLoadingCount } from "../../common/LoadingSlice";

const updateHeader = (event, header, saveCallback, dispatch, t) => {
  event.preventDefault();
  dispatch(addLoadingCount())
  return new Headers().Update(header)
    .then((result) => {
      if (result.status === 200) {
        new Headers().Get("default.json").then(setHeader).then(dispatch).then(() => {
            if (saveCallback) {
              saveCallback(header)
            }
          })
          messageStore.sendMessage(t('saveSuccess'))
      } else {
        messageStore.sendMessage(result.statusText, "error", t('saveError') )
      }
    }).finally(() => dispatch(subLoadingCount()))
}



  export {updateHeader}