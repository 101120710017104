import React  from 'react';
import { Paper, Typography } from "@mui/material";
import PropTypes from 'prop-types';

const EmptyView = ({ title, children }) => {
    return (
      <Paper id="visualisation" sx={{ width: '90%', maxWidth: 500, margin: 'auto', overflow: 'hidden' }}>
        <Typography sx={{ my: 1, mx: 0 }} component={'span'} color="text.secondary" align='center'>
          {title}
          {children}
        </Typography>
      </Paper>
    );
  }

  EmptyView.propTypes = {
    title: PropTypes.any.isRequired,
    children: PropTypes.node
  };
  

  export default EmptyView