import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GetUserIcon } from '../../services/authenticate';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import { accountUrl, logoutUrl, profileUrl } from '../Navigator';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { ChangeClientNo } from '../../script/ChangeClientNo';
import { hasModuleCieSetting } from '../../services/AccessHub';

function Top(props) {
  const [t, i18n] = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userMenuOpen = Boolean(anchorEl);
  const { onDrawerToggle } = props;

  const userIcon = GetUserIcon(props.username)
  let navigate = useNavigate(); 

  const ACCOUNT_ID = "account"
  const PROFILE_ID = "profile"
  const LOGOUT_ID = "logout"
  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUserClose = (event) => {
    setAnchorEl(null);
    if (event?.target.id === LOGOUT_ID) {
      navigate(logoutUrl)
    } else if (event?.target.id === ACCOUNT_ID) {
      navigate(accountUrl)
    } else if (event?.target.id === PROFILE_ID) {
      navigate(profileUrl)
    }

  };



  const upgreadeItm = () => {
    //ChangeClientNo("10___Katherine_Lemay", "10")  
    ChangeClientNo("3___Jean-Rene_Saucier", "3")
  }


  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                id='openDrawer'
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item visibility="hidden">
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              
              <Tooltip title={props.username}>
                <IconButton aria-label="btnUser" color="inherit" sx={{ p: 0.5 }} onClick={handleUserClick}>
                  {userIcon}
                </IconButton>
              </Tooltip>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={userMenuOpen}
                onClose={handleUserClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem data-testId="itmProfil" id={PROFILE_ID} onClick={handleUserClose}><PersonOutlineIcon />{t('account.title')}</MenuItem>
                { hasModuleCieSetting() && 
                  <MenuItem data-testId="itmAccount" id={ACCOUNT_ID} onClick={handleUserClose}><SettingsIcon />{t('header.title')}</MenuItem>
                }
                <MenuItem data-testId="itmLogout" id={LOGOUT_ID} onClick={handleUserClose}><LogoutIcon/>{t('session.logout')}</MenuItem>
                { 1 === 0 && 
                  <MenuItem onClick={upgreadeItm}><LogoutIcon/>upgreade item</MenuItem>
                }
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Top.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Top;
