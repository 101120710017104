
import { userStore } from "../component/common/Session";
import userpool from "../component/userpool";



export const Logout = () => {
    const user = userpool.getCurrentUser();
    if (user) {
      user.signOut(() => {
        userStore.setUser({})
        window.location.href = '/';
      }
      );
    }
};