import Limits from "../api/Limits"
import { askReleaseLimits } from "../component/common/CommonSlice";
import { HasRole } from "./authenticate"
import ReactGA from "react-ga4";

//Profile
const PROFILE_TEC = "tec-profile"
const PROFILE_VET = "vet-profile"
const PROFILE_READONLY = "read-only-profile"
const PROFILE_CLIENT = "client-profile"
export const hasProfileTec = () => {
    return HasRole(PROFILE_TEC)
}
export const hasProfileVet = () => {
    return HasRole(PROFILE_VET)
}
export const hasProfileReadOnly = () => {
    return HasRole(PROFILE_READONLY)
}
export const hasProfileClient = () => {
    return HasRole(PROFILE_CLIENT)
}
//Module
const MOD_RX = "module-rx"
const MOD_VACCINE = "module-vaccine"
const MOD_BILLING = "module-billing"
const MOD_CONSULTATION = "module-consultation"
const MOD_EVENT = "module-incomming-event"
const MOD_CONFIRM_EVENT = "module-confirm-event"
const MOD_CIE_SETTING = "module-cie-setting"


//Module access
export const hasModuleRx = () => {
    return HasRole(MOD_RX)
}
export const hasModuleVaccine = () => {
    return HasRole(MOD_VACCINE)
}
export const hasModuleBilling = () => {
    return HasRole(MOD_BILLING)
}
export const hasModuleConsultation = () => {
    return HasRole(MOD_CONSULTATION)
}
export const hasModuleInccomingEvent = () => {
    return HasRole(MOD_EVENT)
}
export const hasModuleEventConfirm = () => {
    return HasRole(MOD_CONFIRM_EVENT)
}
export const hasModuleCieSetting = () => {
    return HasRole(MOD_CIE_SETTING)
}


////Specific action permission


const P_LOGO = "permission-logo"
export const hasPermissionLogo = () => {
    return HasRole(P_LOGO) && hasProfileVet()
}

const P_SIGN = "permission-signature"
export const hasPermissionSignature = () => {
    return HasRole(P_SIGN) && hasProfileVet()
}

const P_UNLIMITED = "permission-unlimited"
export const hasPermissionUnlimitedAction = () => {
    return HasRole(P_UNLIMITED)
}

export const hasDeletePermission = () => {
    return !(hasProfileClient() || hasProfileReadOnly())
}

export const hasEditPermission = () => {
    return !(hasProfileClient() || hasProfileReadOnly())
}

//Create acces base on limits
export const canCreateRx = (limits) => {
    return validateAction("rx", limits)
}

export const canCreateVaccine = (limits) => {
    return validateAction("vaccine", limits)
}

export const canCreateConsultation = (limits) => {
    return validateAction("consultation", limits)
}

export const canCreateBill = (limits) => {
    return validateAction("bill", limits)
}

const validateAction = (actionName, limits) => {
    if (hasPermissionUnlimitedAction()) {
        return true
    }
    return numberAction(actionName, limits) > 0
}


//Limits
export const numberRxLimit = (limits) => {
    return numberAction("rx", limits)
}

export const numberVaccineLimit = (limits) => {
    return numberAction("vaccine", limits)
}

export const numberConsultationLimit = (limits) => {
    return numberAction("consultation", limits)
}

export const numberBillLimit = (limits) => {
    return numberAction("bill", limits)
}

const numberAction = (actionName, limits) => {
    if (limits && limits[actionName]) {
        return limits[actionName]
    }
    return 0
}


/**
 * 
 * @param {*} action 
 */
export const handleAction = (action, dispatch) => {

    ReactGA.event("generate_lead", {"currency": "CAD", "value": 1, "lead_source": action});
    if (!hasPermissionUnlimitedAction(action)) {
        new Limits().DecreateLimit(action)
        .then(askReleaseLimits)
        .then(dispatch)
    }
    
}


