import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';

import { setHeader } from '../common/CommonSlice';
import { setAnimal, setClient } from '../common/ClientSlice';
import { useDispatch, useSelector } from 'react-redux';
import AnimalSelector from '../animals/AnimalSelector';
import Animals from '../../api/Animals';
import Headers from '../../api/Headers';
import { Box } from '@mui/material';
import ClientSelector from '../clients/ClientSelector';
import { useTranslation } from 'react-i18next';
import { messageStore } from '../common/InfoMessage';


function ClientHeader({showAnimals, allowSelection, showClients, allowAnimalClearable}) {
    const [t, i18n] = useTranslation()
    const client = useSelector(state => state.client.client);
    const animal = useSelector(state => state.client.animal);
    const clientNo = client?.payload ? client.payload.no : null
    const clientKey = client?.payload ? client.payload.key : null
    const animalKey = animal?.payload ? animal.payload.key : null
    const dispatch = useDispatch()

    if (showAnimals === undefined || showAnimals === null) { 
        showAnimals = true;
    }
    if (allowSelection === undefined || allowSelection === null) { 
        allowSelection = true;
    }
    return (
    <React.Fragment>
        <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
        >
        <Toolbar>
            <Grid sx={{ my: 1 }} container alignItems="left" spacing={0} direction={'column'}>
            
                { showClients !== false && 
                <Box color="text.secondary"  align="left" >
                    <b>{t('client.title')} :</b> 
                    <ClientSelector 
                        disable={!allowSelection}
                        clientKey={clientKey}
                        setClient={(clientData) => dispatch(setClient(clientData))}
                        loadClient={true}
                        />
                </Box>
                }
        
                {showAnimals && 
                <Box color="text.secondary"  align="left" >
                    <b>{t('animal.title')} :</b> 
                    <AnimalSelector
                        loadAnimal={true}
                        disable={!allowSelection}
                        clientNo={clientNo}
                        animal={animalKey}
                        allowClearable={allowAnimalClearable}
                        setAnimal={(animalData) => dispatch(setAnimal(animalData))} />
                </Box>
               }
        
            </Grid>
        </Toolbar>
        </AppBar>
    </React.Fragment>)
}


export default ClientHeader