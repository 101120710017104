import { CognitoUserPool } from 'amazon-cognito-identity-js';
import env from '../env/env.json';
import {messageStore} from './common/InfoMessage'
import { logger } from './common/Logger';


const poolData = {
  UserPoolId: env.REACT_APP_USER_POOL_ID,
  ClientId: env.REACT_APP_CLIENT_ID,
  storage: window.sessionStorage 
};
var idToken = null;
const userPool = new CognitoUserPool(poolData)

function GetAccessToken() {
 
  if (userPool.getCurrentUser() == null) {
    return null
  }

  if (idToken != null) {
    //Validate current token
    if (new Date().getTime()/1000 > idToken.payload.exp) {
      idToken = null
    } else {
      return idToken.jwtToken
    }
  }
   
  idToken = getNewToken()

  return idToken.jwtToken
}

function logout() {
  idToken = null
}


function getNewToken() {
  return userPool.getCurrentUser().getSession((error, session) => {
    if (error != null) {
      idToken = null;
      messageStore.sendMessage("Erreur lors de la génération du token : ", "error", error)
    } else {
      logger.debug("New token define!")
      idToken = session.idToken
    }
    return idToken
  });
}

export default new CognitoUserPool(poolData);

export {GetAccessToken, logout, getNewToken}