import {Parser} from 'xml2js';
import env from '../env/env.json';
import axios from 'axios';
import {GetAccessToken, GetClientKey, GetSubClientKey} from '../services/authenticate'
import { formatToSystemDate } from '../component/date/DateUtils';
import { logger } from '../component/common/Logger';

const parser = new Parser();

function GetKeys(xmlResponse) {

  logger.trace("GetKeys : ", xmlResponse)
  let keys = []
  parser.parseString(xmlResponse, (err, result) => {
    if (!result.ListBucketResult.Contents) {
      return keys;
    }
    result.ListBucketResult.Contents.map((item) => {
      const elementKey = item.Key[0]
      if (!elementKey.endsWith("/")) {
        var fileNameArray = elementKey.split("/")
        //
        //fileNameArray = fileNameArray.slice((fileNameArray.length -1) * -1)
        fileNameArray = fileNameArray.slice(2);
        const fileName =  fileNameArray.join("/")

        if (fileName !== null && fileName !== "") {
          keys.push(fileName)
        }
      }
    });
  });

  logger.trace("Keys : ", keys)
  return keys;
}

const constructPrefix = (cKey, cSubKey, elementName) =>{
  return cKey + "/" + elementName + "/" + (cSubKey ? cSubKey : "")
}


function ListElements(elementName) {
 const token = GetAccessToken() 
 const cKey = GetClientKey()

 const APIEndPoint =  env.adminApi + elementName
 logger.debug("Fetch " + elementName);

  return fetch(APIEndPoint,{headers: {
          "X-API-Key": env.adminKey,
          "Content-Type": "application/xml",
          "Authorization": 'Bearer ' + token,
          "ClientKey": cKey,
          "Prefix": constructPrefix(cKey, GetSubClientKey(), elementName)
       }
       })
   .then((response) => response.text())
   .then((data) => new GetKeys(data))
   .catch((err) => {
      console.error("Error ListElements : ", err);
      throw new Error(err.message)
   })
}

function GetDataElement(elementName, key) {
  const token = GetAccessToken() 

  if (!token) {
    logger.info("INFO : No session yet")
   return new Promise( () => {return ""})
  }

  if (key === "" ) {
    logger.debug("INFO : GetElement with null key")
   return new Promise( () => {return ""})
  }

  const APIEndPoint =  env.adminApi + elementName + "/" + encodeURIComponent(key)
  return fetch(APIEndPoint,{headers: {
          "X-API-Key": env.adminKey,
          "Content-Type": "application/xml",
          "Authorization": 'Bearer ' + token,
          "ClientKey": GetClientKey()
       }
       })
   .then((response) => response.text())  //new Blob([ response.data], {type: 'image/png'}))
   .catch((err) => {
    logger.error("Error GetElement :", err);
      return ""
   })
}

function GetElement(elementName, key) {
  const token = GetAccessToken() 

  if (!token) {
    logger.info("INFO : No session yet")
   return new Promise( () => {return ""})
  }

  if (key === "" ) {
    logger.debug("INFO : GetElement with null key")
   return new Promise( () => {return ""})
  }

  const APIEndPoint =  env.adminApi + elementName + "/" + encodeURIComponent(key)
  return fetch(APIEndPoint,{headers: {
          "X-API-Key": env.adminKey,
          "Content-Type": "application/xml",
          "Authorization": 'Bearer ' + token,
          "ClientKey": GetClientKey()
       }
       })
   .then((response) => response.json())
   .catch((err) => {
    logger.error("Error GetElement :", err);
      return ""
   })
}


function InsertElement(elementName, key, formData) {
  key = escapeKey(key)
  return new GetElement(elementName, key)
  .then((existingElement) => 
  {
    if (existingElement != null && existingElement !== "") {
      throw new Error("Impossible d'insérer un élément qui existe déjà. Clé : " + key)
      //return new Promise(function(resolve) {resolve({status: "500", statusText: "Impossible d'insérer un élément qui existe déjà. Clé : " + key})})
    }
      const dataWithDt = {...formData, creationDate: formatToSystemDate(new Date())}
     return internalPutElement(elementName, key, dataWithDt)
  })
}

function internalPutElement(elementName, key, formData) {
  const token = GetAccessToken() 
  const APIEndPoint = env.adminApi + elementName + '/'
  const publish = async (key, data) => {
      try {
        const encodeKey = encodeURIComponent(key)
        const response = await axios.put(APIEndPoint + encodeKey, data ,
          {headers: {
            "X-API-Key": env.adminKey,
            "Authorization": 'Bearer ' + token,
            "ClientKey": GetClientKey()
              }
          });
        response.data = data
        logger.debug('Response:', response.data);
        return response
      } catch (error) {
        logger.error('Error InsertElement :', error);
        throw new Error(error.message)
      }
  };
  return publish(key, formData)
}


function UpdateElement(elementName, key, formData, updateDate) {
  key = escapeKey(key)
  return new GetElement(elementName, key)
  .then((existingElement) => 
  {
    if (existingElement === null || existingElement === "") {
      throw new Error("Impossible de mettre à jour l'élément car il est introuvable. Clé : " + key)
      //return new Promise(function(resolve) {resolve({status: 404, statusText: "Impossible de mettre à jour l'élément car il est introuvable. Clé : " + key})})
    }
    if (updateDate !== false) {
      return internalPutElement(elementName, key, {...formData, updateDate: formatToSystemDate(new Date())})
    }

    return internalPutElement(elementName, key, formData)
  })
}

function HardDeleteElement(elementName, key) {
  const token = GetAccessToken() 
  key = escapeKey(key)

     const APIEndPoint = env.adminApi + elementName + '/'
     const del = async (key) => {
         try {
           const encodeKey = encodeURIComponent(key)
           const response = await axios.delete(APIEndPoint + encodeKey ,
             {headers: {
               "X-API-Key": env.adminKey,
               "Authorization": 'Bearer ' + token,
               "ClientKey": GetClientKey()
                 }
             });
            logger.debug('Response:', response.data);
           return response
         } catch (error) {
          console.error('Error HardDeleteElement :', error);
          throw new Error(error.message)
          //return new Promise(function(resolve) {resolve({status: error.code, statusText: error.message, detail: error})})
         }
     };

     return del(key)
}

function escapeKey(key) {
  return key.trim().replaceAll("é", "e").replaceAll("è", "e").replaceAll("ê", "e")
}



function generateNewKey(clientNo, elementNo, clientName, petName) {
  const baseName = clientNo + "/" + elementNo + "___" + clientName;

  return escapeKey(petName ? baseName + "___" + petName: baseName).replaceAll(" ", "_") 
}




export {GetKeys, GetDataElement, ListElements, GetElement, InsertElement, UpdateElement, generateNewKey, escapeKey, HardDeleteElement, internalPutElement}