

export const selectOptionTitreWithKey = (option) => {
    if (!option) {
        return null
    }

    let displayValue = "# " + option.substring(option.indexOf("/") + 1).replaceAll("___", " - ").replaceAll("_", " ");
    return displayValue;
}

export const selectOptionTitreNoKey = (option) => {
    if (!option) {
      return null
    }

    let displayValue = option.substring(option.indexOf("___") + 3).replaceAll("_", " ");
    return displayValue;
  }
