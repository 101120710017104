
import { logger } from '../component/common/Logger';
import { GetElement, UpdateElement} from './S3Helper';

const ELEMENT_NAME = "common"
const KEY_NAME = "default"
function GetCommon() {
   return GetElement(ELEMENT_NAME, KEY_NAME)
}

async function UpdateCommon(data) {
   return await UpdateElement(ELEMENT_NAME, KEY_NAME, data)
}

async function ReserveNo(currentNo, fieldName) {
   const common = await GetCommon()
   if (common[fieldName] === undefined || currentNo === common[fieldName]) {
      common[fieldName] = currentNo + 1;
      logger.debug("New no :", fieldName, common[fieldName])

      await UpdateCommon(common)
      return currentNo
   } else {
      logger.info("Missmatch in fieldNumber. NOTHING SAVE", fieldName)
      logger.info("currentNo : " + currentNo)
      logger.info("common : " + common[fieldName])
      throw new Error("Missmatch in fieldName " + fieldName)
   }
   
}

export {GetCommon, UpdateCommon, ReserveNo}