
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import FlatHeaderRenderer from '../header/FlatHeaderRenderer';
import NoMoreActionView from '../view/NoMoreActionView';
import FactureTable from './FactureTable';
import { useDispatch, useSelector } from 'react-redux';
import { emptyFacture, emptyItem, extraSection } from './BaseFacture';
import {CreateButtonSection} from '../view/CreateButtonSection';
import { messageStore } from '../common/InfoMessage';
import Clients from '../../api/Clients';
import EmptyView from '../view/EmptyView';
import { addRow, deleteRow, setNextNoHandler } from '../view/TableEditionHandler';
import DayPicker from '../date/DatePicker';
import { convertToSystemDateFormat, formatToSystemDate } from '../date/DateUtils';
import BillSelector from './BillSelector';
import { ReserveNo } from '../../api/Common';
import { setClient } from '../common/ClientSlice';
import { useTranslation } from 'react-i18next';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import { LoadHeader } from '../headers/HeaderLoader';
import { canCreateBill, handleAction } from '../../services/AccessHub';
import ReactGA from "react-ga4";
import { logger } from '../common/Logger';
const itemNoFieldName = "factureNo"

export function CreateBill({itemToEdit, saveCallback}) {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()
  const isUpdate = (itemToEdit)
  const header = useSelector(state => state.common.header)
  const curClient = useSelector(state => state.client.client)
  const curAnimal = useSelector(state => state.client.animal)   
  const [bill, setBill] = useState(isUpdate ? itemToEdit : emptyFacture);
  const limits = useSelector(state => state.common.limits)?.payload

  React.useEffect(() => {
    handleSetNo();
  }, [bill, isUpdate]);


  const handleSetNo = () => {
    if (!isUpdate && bill.no === "") {
      logger.debug("Need new no")
      setNextNoHandler(itemNoFieldName, bill, setBill, dispatch)
    }
  }

  logger.debug("curBill : ", bill)
    
  // Fonction pour mettre à jour les valeurs des champs
  const internalHandleInputChange = (index, fieldName, value) => {
    var newBill = JSON.parse(JSON.stringify(bill))
    
    if (index !== null && bill.items[index][fieldName] !== value) { 
      logger.debug("current bill : ", bill)
      newBill.items[index][fieldName] = value;
      const newTotalPrice = parseFloat(newBill.items[index]["quantite"] * newBill.items[index]["unitPrice"]).toFixed(2);
      newBill.items[index]["totalPrice"] = newTotalPrice
      

      const total = calculTotalPriceItems(newBill.items)
      newBill.total = total
      setBill(newBill)
      logger.debug("updated bill : ", newBill)
    } else if (index === null && fieldName) {
      setBill({...bill,
        [fieldName]: value
      })
    }
  };

  const calculTotalPriceItems = (items) => {
    let total = parseFloat(0);
    items.map((item) => {
      total += parseFloat(item.totalPrice)
    })
    return total.toFixed(2)
  }
  
  const internalHandleSave = async (event) => {
    event.preventDefault();
    const clientUpdate = JSON.parse(JSON.stringify(curClient.payload))
    var newBill = {...bill}
    if (curAnimal && curAnimal.payload) {
      newBill.animal = {
        "no": curAnimal.payload.no,
        "name": curAnimal.payload.name, 
        "birthdate": curAnimal.payload.birthdate,
        "micropuce": curAnimal.payload.micropuce,
        "race": curAnimal.payload.race,
        "type": curAnimal.payload.type,
        "sexe": curAnimal.payload.sexe
      }
    }
    
    dispatch(addLoadingCount())
    if (isUpdate) {
      newBill.updateDate = formatToSystemDate(new Date())
      const objIndex = clientUpdate.bills.findIndex(obj => obj.no === newBill.no);
      clientUpdate.bills[objIndex] = newBill

      updateClient(clientUpdate, newBill, isUpdate, saveCallback, dispatch, t)
      .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
      .then(() => dispatch(subLoadingCount()))
    } else {
      handleAction("bill", dispatch)
      newBill.creationDate = formatToSystemDate(new Date())
      clientUpdate.bills = clientUpdate.bills ? [...clientUpdate.bills, newBill] : [newBill]
      await ReserveNo(newBill.no, itemNoFieldName)
      .then(() => updateClient(clientUpdate, newBill, isUpdate, saveCallback, dispatch, t))
      .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
      .then(() => dispatch(subLoadingCount()))
    }
  }


  const getView =(header, curClient, curAnimal, bill) => {
    return (
    <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <LoadHeader />
      { isUpdate &&
        <BillSelector client={curClient} bill={bill} disable={true}/>
      }
              
      <form id="billForm"  onSubmit={internalHandleSave}>
          <FlatHeaderRenderer 
              displayHeaderLeft={true}
              headerData={header} 
              clientData={curClient} 
              animalData={curAnimal}
              extraData={{"title": t('billing.item.title'),
                  "noTitle": t('billing.item.no'),
                  "no": bill.no,
                  "date": 
                    <DayPicker 
                      value={ bill.date}
                      inputChange={ (e) => internalHandleInputChange(null, "date", convertToSystemDateFormat(e))}
                      />
                  }}
              extraChildren={extraSection(bill, header?.preference)}  
                  />
  
      <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" align='center' variant="h5">
        {t('billing.mainTitle')}
      </Typography>
      <FactureTable 
        items={bill.items} 
        handleInputChange={internalHandleInputChange} 
        deleteItem={(index) => deleteRow(bill, setBill, index)}
        isEditMode={true}/>
  
      <br/>
      <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" align='center' variant="h6">
        {t('billing.totalLong')} : {bill.total} $
      </Typography>
      <CreateButtonSection addRowHandler={() => addRow(bill, setBill, emptyItem)} />
  </form>
  </Paper>)
  }

  if (!canCreateBill(limits)) {
    return (<NoMoreActionView/>)
  }

  if ((bill === undefined || bill === null) || (curClient === undefined || curClient === null)) {
    return (<EmptyView title={t('client.noSelection')} />)
  }

  return getView(header?.payload, curClient?.payload, curAnimal?.payload, bill)
}




const updateClient = (clientUpdate, bill, isUpdateBill, saveCallback, dispatch, t) => {
  return new Clients().Update(clientUpdate, false).then((result) => {
    if (result.status === 200) {
      new Clients().Get(clientUpdate.key).then(setClient)
      .then(dispatch)
      .then(() => {saveCallback(bill)})
      if (isUpdateBill) {
        messageStore.sendMessage(t('billing.updateSuccess'))
      } else {
        messageStore.sendMessage(t('billing.saveSuccess'))
      }
    } else {
      messageStore.sendMessage(result.statusText, "error", t('saveError'))
    }
  })
}