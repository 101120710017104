import React from 'react';
import { Paper, styled, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PhoneTextMask } from "../../../common/CommonTextMask";
import TextFieldStyle, { StandardTextField } from '../../../input/TextFieldStyle';


const ConfigCieHeader = ({item, handleInputChange, isEditMode}) => {
    const { t, i18n } = useTranslation();  
    //const variant = isEditMode ? 'outlined' : 'standard'
    const variant = 'standard'
    return (
        <Typography color="text.secondary" align="left" component={'div'}>
            <StandardTextField label={t('header.cieName')}
                maxLength={80}
                testid="txtCieName"
                variant={variant}
                fullWidth
                required
                disabled={!isEditMode}
                onChange={(e) => handleInputChange('name', e.target.value)}
                value={item.name} />
            <StandardTextField label={t('header.phone')}
                testid="txtPhone"
                variant={variant}
                maxLength={15}
                fullWidth
                required
                disabled={!isEditMode}
                onChange={(e) => handleInputChange('phone', e.target.value)}
                value={item.phone}
                inputcomponent={PhoneTextMask}
                />
            <StandardTextField label={t('header.addr1')}
                testid="txtAddr1"
                variant={variant}
                maxLength={128}
                fullWidth
                required
                disabled={!isEditMode}
                onChange={(e) => handleInputChange('addr1', e.target.value)}
                value={item.addr1} />
            <StandardTextField label={t('header.addr2')}
                testid="txtAddr2"
                maxLength={80}
                variant={variant}
                fullWidth
                disabled={!isEditMode}
                onChange={(e) => handleInputChange('addr2', e.target.value)}
                value={item.addr2} />
            <StandardTextField label={t('header.email')}
                testid="txtEmail"
                maxLength={80}
                variant={variant}
                fullWidth
                disabled={!isEditMode}
                onChange={(e) => handleInputChange('email', e.target.value)}
                value={item.email} />
        </Typography>
    )
}


export default ConfigCieHeader 