import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter } from 'react-router-dom';
import store from './component/common/store'
import { Provider } from 'react-redux'
import './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <React.StrictMode>

    <HashRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </HashRouter>

  </React.StrictMode>
);

