import React  from 'react';
import { ConsultationIcon, FactureIcon, PrescriptionIcon, VaccinIcon } from '../common/IconFactory';
import InfoItemTooltip from '../card/InfoItemTooltip';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { hasModuleBilling, hasModuleConsultation, hasModuleRx, hasModuleVaccine, hasProfileVet } from '../../services/AccessHub';

function AnimalAction({animalData}) {
    const vetAction = hasProfileVet()
    const [t, setT] = useTranslation();
    const navigateAction = useNavigate();

    const vac = vetAction && hasModuleVaccine() ? (<VaccinIcon
        title={t("button.createVaccine")}
        onClick={(e) => createVaccin(navigateAction, animalData)}
        />) : (<></>);
    
    const rx = vetAction && hasModuleRx() ? (<PrescriptionIcon
        title={t("button.createRx")}
        onClick={(e) => createPrescription(navigateAction, animalData)}
        />) : (<></>);

    const cons = vetAction && hasModuleConsultation() ? (<ConsultationIcon
        title={t("button.createConsultation")}
        onClick={(e) => createConsultation(navigateAction, animalData)}
        />) : (<></>);

    const bill = hasModuleBilling() ? ( <FactureIcon
        title={t("button.createBill")}
            onClick={(e) => createBill(navigateAction, animalData)}
        />) : (<></>);

    return (<>
        {vac} 
        {rx}
        {cons}
        {bill}
        
        <InfoItemTooltip
            noName={t('animal.item.no')} 
            no={animalData.no} 
            updateDate={animalData.updateDate} 
            creationDate={animalData.creationDate} />
     </>)     
}

function navigateTo(navigateAction, target, animalData, clientDate) {
    navigateAction(target, { state: createNavigationData(animalData, clientDate) });
}

function createBill(navigateAction, animalData, clientDate) {
    navigateTo(navigateAction, '/factures', animalData, clientDate)
}
function  createVaccin(navigateAction, animalData, clientDate) {
    navigateTo(navigateAction, '/vaccins', animalData, clientDate)
} 

function  createPrescription(navigateAction, animalData, clientDate) {
    navigateTo(navigateAction, '/prescriptions', animalData, clientDate)
}

function  createConsultation(navigateAction, animalData, clientDate) {  
    navigateTo(navigateAction, '/consultations', animalData, clientDate)
}


function createNavigationData(animalData, clientDate) {
    return {
      view: "create",
      selectedClient: clientDate,
      selectedAnimal: animalData}
  }




export default AnimalAction