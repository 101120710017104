import React  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import { useState } from "react"
import EventsConfirm from "../../api/EventsConfirm"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import { useDispatch } from 'react-redux';
import { messageStore } from '../common/InfoMessage';

const EventConfirmSelector = ({eventConfirmKey, setEventConfirm, setEventConfirmKey, disable}) => {
  const { t, i18n } = useTranslation();
  const [optionsEvents, setOptionsEvents] = useState(null)
  const dispatch = useDispatch()

    if (optionsEvents === null) {
      dispatch(addLoadingCount())
      new EventsConfirm().List()
      .then(setOptionsEvents)
      .catch((error) => {messageStore.sendMessage(error.message, "error")})
      .finally(()=>dispatch(subLoadingCount()))
    }

    const internalSetEvent = (ev, newValue) => {
      if (setEventConfirmKey) {
        setEventConfirmKey(newValue)
      }
      new EventsConfirm().Get(newValue).then(setEventConfirm)
    }
  
    return (<Autocomplete
        disabled={disable}
        disableClearable
        onChange={internalSetEvent}
        sx={{ maxWidth: 500, width: '100%%', my: 5, mx: 2 }}
        value={eventConfirmKey ? eventConfirmKey : null}
        options={optionsEvents ? optionsEvents : []}
        getOptionLabel={selectOptionTitre}
        renderInput={(params) => <TextField {...params} label={t('eventConfirm.selectorTitle')}/>}
      />)
  }
  
  const selectOptionTitre = (option) => {
    if (!option) {
      return null
    }

    let displayValue = "# " + option.substring(option.indexOf("/") + 1).replaceAll("___", " - ").replaceAll("_", " ");
    return displayValue;
  }

  EventConfirmSelector.propTypes = {
    eventConfirmKey: PropTypes.object,
    setEventConfirm: PropTypes.func.isRequired,
    setEventConfirmKey: PropTypes.func,
    disable: PropTypes.bool
  };

  export default EventConfirmSelector