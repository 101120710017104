import React  from 'react';
import { Grid, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LogoSection } from '../common/Logo';

function CompanyCard({headerData, isPrintMode, displayLogo}) {
  const [t, i18n] = useTranslation()

  return <>
          { headerData && 
            v2(headerData, isPrintMode, displayLogo, t)  
          }
          </>
}

CompanyCard.propTypes = {
  headerData: PropTypes.object.isRequired,
  isPrintMode: PropTypes.bool,
  displayLogo: PropTypes.bool
};

function v2(headerData, isPrintMode, displayLogo, t) {
  return (
  <Grid container spacing={0} columns={10} 
    className='header-grid-container' 
    sx={{ my: 0, mx: 0, color: 'gray', textAlign: 'left', typography: 'body2' }}>
    {displayLogo && 
      <Grid item xs={10} sx={{ color: 'rgb(90, 90, 90)' , typography: 'body1' }} className='header-grid-item' >
        <LogoSection />
      </Grid>
    }
    <Grid item xs={10} sx={{ color: 'rgb(90, 90, 90)' , typography: 'body1'}}>
      <Typography sx={{ my: 1 }} variant="h6">
       {headerData.name}
      </Typography>
    </Grid>

    <Grid item xs={10} >{headerData.addr1}</Grid>
    <Grid item xs={10} >{headerData.addr2}</Grid>
    <Grid item xs={10}  >{t('header.phone')} : {headerData.phone}</Grid>
    <Grid item xs={10} >{t('header.email')} : {headerData.email}</Grid>

  </Grid>)
}

export default CompanyCard