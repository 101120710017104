import Typography from '@mui/material/Typography';
import React from 'react'
import DayPicker from "../date/DatePicker";
import { convertToSystemDateFormat } from '../date/DateUtils';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import {sexeMap, typeMap} from './BaseAnimal'
import AnimalAction from './AnimalAction'
import WeightInput from '../input/WeightInput';
import { GetWeightType } from '../common/CommonSlice';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StandardTextField } from '../input/TextFieldStyle';



function AnimalSection({animalData, isEditMode, handleInputChange, isPrintMode}) {
  const { t, i18n } = useTranslation();

  return (<>
  <Box>
        <Typography key="iconTypo" sx={{ my: 2, mx: 2 }} color="text.secondary" align="right">
          
          {
            !isEditMode && !isPrintMode && <AnimalAction animalData={animalData} />
          }
          
        </Typography>
        <Typography key="headerTypo" sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
          <StandardTextField label={t('animal.item.nameLong')} sx={{ my: 2, mx: 2 }}
            required
            disabled={!isEditMode}
            fullWidth
            minLength={2}
            maxLength={128}
            testid="txtName"
            onChange={(e) => handleInputChange('name', e.target.value)}
            value={animalData.name} variant="standard"/>

          <InputLabel disabled={!isEditMode} htmlFor="animalActif" sx={{ mx: 2 }}>Innactif
            <Checkbox label={t('animal.item.innactive')}  sx={{ mx: 2 }}
              id="animalActif"
              testid="chkActif"
              disabled={!isEditMode}
              checked={!animalData.isActif && animalData.isActif !== undefined} 
              onChange={(e) => isEditMode && handleInputChange('isActif', !e.target.checked)}
              />
          </InputLabel> 
          
          
          </Typography>
          <Typography key="regularTypo" sx={{mx: 2 }} color="text.secondary" align="left">
          
            <FormLabel id="type" sx={{  my: 2, mx: 2 }} disabled={!isEditMode}>{t('animal.item.species')}
              <RadioGroup sx={{ mx: 2 }} id={"an_type_" + animalData.no}
                row
                aria-labelledby="type"
                disabled={!isEditMode}
                name="radio-buttons-group"
                value={animalData.type}
                onChange={(e) => isEditMode && handleInputChange('type', e.target.value)}
              >
                {Object.keys(typeMap).map((key, i) => (
                  <FormControlLabel key={i} required disabled={!isEditMode} value={key} control={<Radio inputProps={{ 'aria-label': "opt" + key }}/>} label={typeMap[key]} />
                ))}
              </RadioGroup>
            </FormLabel>
            <FormLabel id="sexe" sx={{  my: 2, mx: 2 }} disabled={!isEditMode}>{t('animal.item.sex')}
              <RadioGroup sx={{mx: 2 }} id={"an_sexe_" + animalData.no}
                row
                aria-labelledby="sexe"
                disabled={!isEditMode}
                name="radio-buttons-group"
                value={animalData.sexe}
                onChange={(e) => isEditMode && handleInputChange('sexe', e.target.value)}
              >
                {Object.keys(sexeMap).map((key, i) => (
                  <FormControlLabel key={i} required disabled={!isEditMode} value={key} control={<Radio inputProps={{ 'aria-label': "opt" + key }} />} label={sexeMap[key]} />
                ))}

              </RadioGroup>
            </FormLabel>

            <StandardTextField label={t('animal.item.chip')} sx={{ my: 2, mx: 2 }}
              disabled={!isEditMode}
              maxLength={60}
              testid="txtChip"
              onChange={(e) => handleInputChange('micropuce', e.target.value)}
              value={animalData.micropuce} variant="standard"/>

            <StandardTextField id="race" label={t('animal.item.race')} sx={{ my: 2, mx: 2 }}
            required
            disabled={!isEditMode}
            maxLength={60}
            testid="txtRace"
            onChange={(e) => handleInputChange('race', e.target.value)}
            value={animalData.race} variant="standard"/>
        
            <StandardTextField label={t('animal.item.color')} 
              required
              disabled={!isEditMode}
              maxLength={60}
              testid="txtColor"
              onChange={(e) => handleInputChange('color', e.target.value)}
              value={animalData.color} variant="standard"/>
          
            <WeightInput 
              weight={animalData.weight}
              weightType={animalData.weightType}
              isEditMode={isEditMode} 
              defaultWeightType={GetWeightType("other")}
              onWeightChange={(newValue) => handleInputChange('weight', newValue)}
              onWeightTypeChange={(newValue) => handleInputChange('weightType', newValue)}
              />

          </Typography> 
          <Typography key="dateTypo" sx={{ my: 2, mx: 4 }} color="text.secondary" align="left">
            { !isEditMode && 
              <StandardTextField label={t('animal.item.birth')}
              required
              disabled={true}
              value={animalData.birthdate ? animalData.birthdate : "Inconnue"} variant="standard"/>

            }
            { isEditMode &&  
              <>
              <FormLabel id="sexe" sx={{  my: 2, mx: 2 }} >{t('animal.item.birth')} : </FormLabel>
                  <DayPicker 
                    label={t('dateSelectionLong')}
                    inputChange={(e) => handleInputChange('birthdate', convertToSystemDateFormat(e))}
                    required={true}
                    inputProps={{'aria-label': "txtBirthdate"}}
                    value={animalData.birthdate}
                    maxDate={new Date()}/>

                <StandardTextField  label={t('animal.item.birthInMonth')} sx={{ mx: 2, my: -1 }}
                  disabled={!isEditMode}
                  min={0} max={998}  maxLength={3}
                  testid="txtMonthAge"
                  onChange={(e) => handleInputChange('monthAge', e.target.value)}
                  value={animalData.monthAge} type="number" variant="standard"/>
              </>
                }
          
          </Typography>
        </Box>
        </>)

}

export {AnimalSection}