import React  from 'react';
import PropTypes from 'prop-types';
import EmptyView from './EmptyView';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { red } from '@mui/material/colors';

const NoMoreActionView = ({}) => {
  const [t, i18n] = useTranslation()
    return (
      <EmptyView title={ <><WarningAmberIcon fontSize="large" sx={{ color: red[500] }}/> {t('message.noMoreAction')} </>} >
        <Typography data-testid='noMoreActionWarning' variant="body2" color="text.secondary" align="center">
          {t('message.noMoreActionDescription')}
        </Typography>
      </EmptyView>
    );
  }

  EmptyView.propTypes = {
    title: PropTypes.string.isRequired
  };
  

  export default NoMoreActionView