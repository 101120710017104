
import { Paper, Typography } from "@mui/material"

const ConfigTax = ({item, handleInputChange, isEditMode}) => {
    return (
        <Paper elevation={2} sx={{my: 2}} component={'span'}>
            <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" component={'div'}>

            </Typography>
        </Paper>
    )
}


export default ConfigTax 