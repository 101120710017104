import React, { useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import { changePassword } from '../../services/authenticate';
import logo from '../../img/logo-nb.png'
import {messageStore} from '../common/InfoMessage'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import { hasMinLength, validateNewPassword } from './PasswordUtil';
import userpool from '../userpool';
import { logger } from '../common/Logger';
import TextFieldStyle from '../input/TextFieldStyle';

const ChangePasswordUI = () => {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const location = useLocation();
  const email = userpool.getCurrentUser() ? userpool.getCurrentUser().getUsername() : location.state?.email

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const formInputChange = (formField, value) => {
    if (formField === "password") {
      setPassword(value);
    }    
    if (formField === "newPassword") {
      setNewPassword(value);
    }
    if (formField === "confirmNewPassword") {
      setConfirmNewPassword(value);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (password === '') {
        messageStore.sendMessage(t('session.isRequired'), "warning", t('session.changePws.oldPws'))
        resolve(false);
      } else if (!validateNewPassword(password, newPassword, confirmNewPassword, t) ) {
        resolve(false);
      } 
      else if (email === '') {
        resolve({ password: "EMAIL REQUIRED", newPassword: "EMAIL REQUIRED" });
        messageStore.sendMessage("Unknow error", "error")
      }
      else {
        resolve(true);
      }
    });
  };


  const handleNewPasswordSubmit = async (event) => {
    validation()
      .then((succes) => {
        if (succes) {
          dispatch(addLoadingCount())
          changePassword(password, newPassword, email)
          .then((data)=>{
            messageStore.sendMessage(t('session.changePws.passwordChangeSuccess'), "info")
            Navigate('/');
          },(err)=>{

            messageStore.sendMessage(err.message, "error")
            logger.error(err);
          })
          .catch(err=>{
            logger.error(err)
            messageStore.sendMessage(err, "error")
          })
          .finally(()=>dispatch(subLoadingCount()))
        }
      });
  }


  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <img src={logo} className="App-MainLogo" alt="logo" width={1000} height={60}/>
        <Box
            component="nav"
            sx={{ flexShrink: { sm: 0 } }} >
              {t('session.changePws.title')}
        </Box>
        <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
        <div className="login">
          <div className='form'>
            <div className='formfield'>
              <TextFieldStyle
                maxLength={60}
                testid="txtPassword"
                value={password}
                onChange={(e) => { formInputChange("password", e.target.value) }}
                type="password"
                label={t('session.changePws.oldPws')}
              />
            </div>
            <div className='formfield'>
              <TextFieldStyle
                maxLength={60}
                testid="txtNewPassword"
                value={newPassword}
                onChange={(e) => { formInputChange("newPassword", e.target.value) }}
                type="password"
                label={t('session.changePws.newPws')}
              />
            </div>
            <div className='formfield'>
              <TextFieldStyle
                testid="txtConfirmNewPassword"
                maxLength={60}
                value={confirmNewPassword}
                onChange={(e) => { formInputChange("confirmNewPassword", e.target.value) }}
                type="password"
                label={t('session.changePws.confirmPws')}
              />
            </div>
            <div className='formfield'>
              <Button data-testid="btnLogin" type='submit' variant='contained' onClick={handleNewPasswordSubmit}>{t('button.edit')}</Button>
            </div>
          </div>
          </div>
      </Box>
    </Box>
  )
}

export default ChangePasswordUI