import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, styled, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import ConfigWeightSection from './ConfigWeightSection';
import { InfoIconContent, TooltipIconButton } from '../../../common/IconFactory';
import { StandardTextField } from '../../../input/TextFieldStyle';

export const emptyConfig = {"language": "fr", "paiement_email": "", "vetName": "Dre ...", "license": "", "weight": {"consultation": "kg", "prescription": "kg", "vaccin": "kg", "other": "kg"}}

export const MainPreferenceSection = ({item, handleConfigInputChange, isEditMode}) => {
    const { t, i18n } = useTranslation();  
    //const variant = isEditMode ? 'outlined' : 'standard'
    const variant = 'standard'
    return (
        <Typography color="text.secondary" align="left" component={'div'}>
                <FormControl>
                    <FormLabel >{t('header.config.language')}</FormLabel>
                    <RadioGroup 
                        row
                        onChange={(e) => handleConfigInputChange('language', e.target.value)}
                        value={item.language}>
                        <FormControlLabel disabled={!isEditMode} value={"fr"} control={<Radio />} label={t('header.config.fr')} />
                        <FormControlLabel disabled={!isEditMode} value={"en"} control={<Radio />} label={t('header.config.en')} />
                    </RadioGroup>
                </FormControl>  
                <Box component={'div'}>
                <StandardTextField label={t('header.config.paiement_email')}
                    variant={variant}
                    sx={{width: '85%'}}
                    maxLength={128}
                    disabled={!isEditMode}
                    onChange={(e) => handleConfigInputChange('paiement_email', e.target.value)}
                    value={item.paiement_email} />
                    <TooltipIconButton
                        title={t('header.config.paiement_emailInfo')}>
                        <InfoIconContent />
                    </TooltipIconButton>
                </Box>
                <StandardTextField label={t('header.config.vetName')}
                    variant={variant}
                    fullWidth
                    required
                    maxLength={80}
                    disabled={!isEditMode}
                    onChange={(e) => handleConfigInputChange('vetName', e.target.value)}
                    value={item.vetName} />
                <StandardTextField label={t('header.config.liscence')}
                    variant={variant}
                    fullWidth
                    required
                    maxLength={50}
                    disabled={!isEditMode}
                    onChange={(e) => handleConfigInputChange('license', e.target.value)}
                    value={item.license} />
       
        </Typography>)
}


export const ConfigGeneralPreference = ({item, handleConfigInputChange, isEditMode}) => {
    return (
        <>
        <Box sx={{ my: 2, mx: 4 }} >
        <Paper elevation={2} sx={{my: 2}} component={'span'}>
            <MainPreferenceSection item={item} handleConfigInputChange={handleConfigInputChange} isEditMode={isEditMode}/>
        </Paper>
        <Paper elevation={2} sx={{my: 2}} component={'span'}>
            <ConfigWeightSection item={item} handleConfigInputChange={handleConfigInputChange} isEditMode={isEditMode} />
        </Paper>
        </Box>
        </>)
}